import * as moment from 'moment';
import { LocalStorageService } from './../utils/local-storage.service';
import constant from './../app.constant';

export class CommonLogicService {
    public localData: any = new LocalStorageService();

    public getUtcOffset() {
        return new Date().getTimezoneOffset() * -1;
    }
    public getCurrentDate() {
        return moment(new Date()).format(constant.DateFormatServerSideWithDateTime);
    }

    public isObject(value: any) {
        return typeof value === 'object';
    }
    public dateFormaterStringToJson(date: any) {
        var reqJson: any = {}
        reqJson.day = parseInt(moment(date, 'YYYY-MM-DD').format('DD'));
        reqJson.month = parseInt(moment(date, 'YYYY-MM-DD').format('MM'));
        reqJson.year = parseInt(moment(date, 'YYYY-MM-DD').format('YYYY'));
        return reqJson;
    }
    public convertUTCDateToLocalDate(date: any) {
        var local = moment.utc(date).local().format('lll');
        return local;
    };
    public givenDateTimeAndOffsetToChangeUtcTimeEvent(dateTime: any, givenFormat?: any, resultFormat?: any) {
        givenFormat = givenFormat ? givenFormat : constant.DateFormatServerSideWithDateTime;
        resultFormat = resultFormat ? resultFormat : constant.DateFormatServerSideWithDateTime;
        var reqUtcOffset = -1 * this.getUtcOffset();
        var utcTime = moment.utc(dateTime, givenFormat).utcOffset(reqUtcOffset).format(resultFormat);
        return utcTime;
    }
    public givenDateTimeAndOffsetToChangeUtcTime(dateTime: any) {
        var reqUtcOffset = -1 * this.getUtcOffset();
        var utcTime = moment.utc(dateTime, constant.dateTimeFormat).utcOffset(reqUtcOffset).format(constant.DateFormatServerSideWithDateTime);
        return utcTime;
    }
    public orderStatus(data: any, module_id: any) {
        switch (module_id) {
            case constant.moduleId.ekart:
                return data;
            case constant.moduleId.foodCourt:
            case constant.moduleId.tableOrdering:
                if (data == constant.orderStatusName[3]) {
                    return 'Cooking';
                } else if (data == constant.orderStatusName[2]) {
                    return "Accept";
                } else {
                    return data;
                }
            case constant.moduleId.service:
                if (data == constant.orderStatusName[2]) {
                    return 'Accept';
                } else if (data == constant.orderStatusName[4]) {
                    return "Cancelled";
                } else {
                    return data;
                }
        }
    }
    public deliveryType(data: any, module_id: any) {
        if (data && module_id)
            return constant?.DeliveryType[module_id][data];
    }
    public add(val: any, val2: any) {
        return Number(Number(val) + Number(val2)).toFixed(2)
    }
    public moduleWiseOrderStatus(data: any) {
        switch (data) {
            case constant.moduleId.ekart:
                data = [{ order_status_name: 'Pending', order_status_id: 1 }, { order_status_name: 'Received', order_status_id: 2 }, { order_status_name: 'Processing', order_status_id: 3 },
                { order_status_name: 'Unserviceable', order_status_id: 4 }, { order_status_name: 'Ready', order_status_id: 5 }, { order_status_name: 'Completed', order_status_id: 6 }];
                break;
            case constant.moduleId.foodCourt:
                data = [{ order_status_name: 'Pending', order_status_id: 1 }, { order_status_name: 'Accept', order_status_id: 2 }, { order_status_name: 'Cooking', order_status_id: 3 },
                { order_status_name: 'Unserviceable', order_status_id: 4 }, { order_status_name: 'Ready', order_status_id: 5 }, { order_status_name: 'Completed', order_status_id: 6 }];
                break;
            case constant.moduleId.service:
                data = [{ order_status_name: 'Pending', order_status_id: 1 }, { order_status_name: 'Cancelled', order_status_id: 4 },];
                break;
            case constant.moduleId.tableOrdering:
                data = [{ order_status_name: 'Pending', order_status_id: 1 }, { order_status_name: 'Accept', order_status_id: 2 }, { order_status_name: 'Cooking', order_status_id: 3 },
                { order_status_name: 'Unserviceable', order_status_id: 4 }, { order_status_name: 'Ready', order_status_id: 5 }, { order_status_name: 'Completed', order_status_id: 6 }];
                break;
        }
        return data;
    }
}

let commonLogics = new CommonLogicService();
export default commonLogics;
