// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Admin
  // Service: {
  //   EngajeLoginApiBaseUrl: "https://demo.engaje.app/engaje/",
  //   EngajeBusinessApiBaseUrl: "https://demo.engaje.app/engajecommerce/",
  //   ImageBaseUrl: 'https://demo.engaje.app/adimage/',
  //   bussinessClientUrl: 'https://demo.engaje.app/',
  //   partnerClientUrl: 'https://demopartner.engaje.app/',
  //   deviceUrl: 'https://demoscan.engaje.app/init/',
  //   referralUrl: 'https://demo.engaje.app/sessions/create',
  //   // EngajeLoginApiBaseUrl: "https://192.168.1.33:6021/engaje/",
  //   // EngajeBusinessApiBaseUrl: "https://192.168.1.40:6024/engajecommerce/",
  //   AuthBaseUrl: 'https://demoadmin.engaje.app/'
  // }
  //partner
  // Service: {
  //   EngajeLoginApiBaseUrl: "https://demo.engaje.app/engaje/",
  //   EngajeBusinessApiBaseUrl: "https://demo.engaje.app/engajecommerce/",
  //   ImageBaseUrl: 'https://demo.engaje.app/adimage/',
  //   bussinessClientUrl: 'https://demo.engaje.app/',
  //   partnerClientUrl: 'https://demopartner.engaje.app/',
  //   deviceUrl: 'https://demoscan.engaje.app/init/',
  //   referralUrl: 'https://demo.engaje.app/sessions/create',
  //   // EngajeLoginApiBaseUrl: "https://192.168.1.33:6021/engaje/",
  //   // EngajeBusinessApiBaseUrl: "https://192.168.1.33:6024/engajecommerce/",
  //   AuthBaseUrl: 'https://demopartner.engaje.app/'
  // }
  // Service: {
  //   EngajeLoginApiBaseUrl: "https://api.engaje.app/engaje/",
  //   EngajeBusinessApiBaseUrl: "https://api.engaje.app/engajecommerce/",
  //   ImageBaseUrl: 'https://api.engaje.app/adimage/',
  //   partnerClientUrl: 'https://api.engaje.app/partner/',
  //   deviceUrl: 'https://demoscan.engaje.app/init/',
  //   // partnerClientUrl: 'http://localhost:5001/',
  //   // bussinessClientUrl: 'http://localhost:5000/',
  //   bussinessClientUrl: 'https://api.engaje.app/',
  //   // EngajeLoginApiBaseUrl: "https://192.168.1.43:6021/engaje/",
  //   // EngajeBusinessApiBaseUrl: "https://192.168.1.43:6024/engajecommerce/",
  //   AuthBaseUrl: 'https://api.engaje.app/partner/'
  // }
  // devadmin
  Service: {
    EngajeLoginApiBaseUrl: "https://dev.engaje.app/engaje/",
    EngajeBusinessApiBaseUrl: "https://dev.engaje.app/engajecommerce/",
    ImageBaseUrl: 'https://dev.engaje.app/adimage/',
    bussinessClientUrl: 'https://dev.engaje.app/',
    partnerClientUrl: 'https://devpartner.engaje.app/',
    deviceUrl: 'https://devscan.engaje.app/init/',
    referralUrl: 'https://dev.engaje.app/sessions/create',
    // EngajeLoginApiBaseUrl: "https://192.168.1.45:6021/engaje/",
    // EngajeBusinessApiBaseUrl: "https://192.168.1.45:6024/engajecommerce/",
    AuthBaseUrl: 'https://devadmin.engaje.app/'
  }
  //devpartner
  // Service: {
  //   EngajeLoginApiBaseUrl: "https://dev.engaje.app/engaje/",
  //   EngajeBusinessApiBaseUrl: "https://dev.engaje.app/engajecommerce/",
  //   ImageBaseUrl: 'https://dev.engaje.app/adimage/',
  //   bussinessClientUrl: 'https://dev.engaje.app/',
  //   partnerClientUrl: 'https://devpartner.engaje.app/',
  //   deviceUrl: 'https://devscan.engaje.app/init/',
  //   referralUrl: 'https://dev.engaje.app/sessions/create',
  //   // EngajeLoginApiBaseUrl: "https://192.168.1.33:6021/engaje/",
  //   // EngajeBusinessApiBaseUrl: "https://192.168.1.33:6024/engajecommerce/",
  //   AuthBaseUrl: 'https://devpartner.engaje.app/'
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
